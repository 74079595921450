import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import ActiveModal from "../../../components/Modal/DeleteModal";
import Widget from "../../../components/Widget/index.js";
import ViewNotes from "../../../components/ViewNotes.js";
import RePublish from "./components/RePublish.js";
import BidsDetailModel from "../BidsDetailModel.js";
import DetailUserModel from "../DetailUserModel.js";
import { dateFormate, formatAsNOK } from "../../../utils/commonFunction.js";
import SingleViewApprovalAuction from "../../../components/AuctionDetailModal/SingleViewApprovalAuction.js";
import { DataGetAction } from "../../../redux/actions/CommonHttp.js";

const Index = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const targetRef = useRef();
  const { language } = useSelector((state) => state.Language);

  const resetCloseAuctionBadge = () => {
    dispatch({
      type: "RestSomeAuctionsClosed",
    });
  };
  useEffect(resetCloseAuctionBadge, []);
  const fetchCategory = () => {
    dispatch({
      type: "RestApprovalCounter",
    });
    dispatch(DataGetAction("getCategory", { query: "all" }, "FetchRecord", "categoryList", "categoryLoader"));
  };
  useEffect(fetchCategory, []);

  const renderPrice = (price) => {
    let convertPrice = formatAsNOK(price);
    let amount = `${convertPrice} ${process.env.REACT_APP_CURRENCY} `;
    return amount;
  };
  const renderActions = (record) => {
    console.log("===== record ==test==", record);
    // record.ModelYear = moment(record.ModelYear).format('YYYY-DD-MM');
    return (
      <div className="flex flex-col">
        <ShowModal
          modalName="View_Pending_Auction_Modal"
          record={record}
          buttonName={language["auctions_closed_view"]}
        />
        <ActiveModal
          title={language["auctions_closed_start_process_title"]}
          content={language["auctions_closed_start_process_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="closedAuctionList"
          buttonName={language["auctions_closed_start_process"]}
          selectedItem={{ _id: record._id, status: "inProcess" }}
        />
        <ShowModal modalName="View_Common_Notes" record={record} buttonName={language["auction_view_note"]} />
        <ShowModal modalName="Re_Publish_View_Modal" record={record} buttonName={language["auction_republish"]} />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record?.auctionId}</span>,
      key: "Id",
    },
    {
      title: language["auctions_live_category"],
      render: (record) => <span className="capitalize">{record?.auctions_live_category}</span>,
      key: "auctions_live_category",
    },
    {
      title: language["auctions_live_subcategory"],
      render: (record) => <span className="capitalize">{record?.auctions_live_subcategory}</span>,
      key: "auctions_live_subcategory",
    },
    {
      title: language["auctions_closed_opening_price"],
      render: (record) => <span>{renderPrice(record?.formStructure?.price ?? record.price)}</span>,
      key: "Price",
    },
    {
      title: language["Won_Price"],
      render: (record) => <span>{record?.wonBidPrice || "----"}</span>,
      key: "wonPrice",
    },
    {
      title: language["auctions_closed_createdBy"],
      render: (record) => (
        <div>
          <ShowModal
            modalName="User_Detail_Model"
            record={record}
            className="font-roboto-bold text-14 capitalize hover:underline text-blue-500"
            buttonName={`${record.firstName} ${record.lastName}`}
          />
          <p className="font-roboto-regular my-0 text-14">{record.email}</p>
          <p className="font-roboto-regular my-0 text-14">{record.phoneNumber}</p>
        </div>
      ),
      key: "Created By",
    },
    {
      title: language["auctions_live_noOfBids"],
      render: (record) => (
        <>
          {record?.totalBider > 0 ? (
            <ShowModal
              modalName="Bids_Detail_Model"
              record={record}
              className="hover:underline text-blue-500 cursor-pointer"
              buttonName={record.totalBider}
            />
          ) : (
            "----"
          )}
        </>
      ),
      key: "Bids",
    },
    {
      title: language["auction_won_bidder"],
      render: (record) => (
        <div>
          {record && record?.wonBidder ? (
            <>
              <ShowModal
                modalName="User_Detail_Model"
                record={{ createdBy: record?.wonBidder?._id }}
                className="font-roboto-bold capitalize hover:underline text-blue-500"
                buttonName={`${record && record.wonBidder && record.wonBidder.firstName} ${
                  record && record.wonBidder && record.wonBidder.lastName
                }`}
              />
              <p className="font-roboto-regular my-0  text-14">
                {record && record.wonBidder && record.wonBidder.email}
              </p>
              <p className="font-roboto-regular my-0 text-14">
                {record && record.wonBidder && record.wonBidder.phoneNumber}
              </p>
            </>
          ) : (
            "----"
          )}
        </div>
      ),
      key: "wonBider",
    },
    {
      title: language["auctions_closed_createdAt"],
      render: (record) => <span>{dateFormate(record?.createdAt)}</span>,
      key: "Created At",
    },
    {
      title: language["Closed_At"],
      render: (record) => <span>{dateFormate(record?.expiredAt)}</span>,
      key: "closed",
    },
    // {
    //   title: "Status",
    //   render: (record) => {
    //     <span></span>;
    //   },
    //   key: "status",
    // },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
      fixed: "right",
    },
  ];
  const allColumnKeys = [
    "Id",
    "Title",
    "Price",
    "wonPrice",
    "Created By",
    "Bids",
    "wonBider",
    "Created At",
    "closed",
    "actions",
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => {};
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Widget>
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />
      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "closed" }}
          apiName="getClosedAuctions"
          recordName="closedAuctionList"
          columns={tableColumns}
        />
      </div>

      <SingleViewApprovalAuction />
      <BidsDetailModel />
      <DetailUserModel />
      <ViewNotes />
      <RePublish />
    </Widget>
  );
};

export default Index;
